import * as React from 'react'
import {
  documents,
  description,
  heading,
  documentList,
  documentsContainer,
  documentListHeading,
  documentWrapper,
  documentContent,
} from './documents.module.scss'
import pdfImage from '../../data/images/pdf.svg'
import downloadImage from '../../data/images/download.svg'

const Document = ({ content, link }) => {
  return (
    <div className={documentWrapper}>
      <img width={50} height={50} src={pdfImage} alt="pdf icon" />
      <div className={documentContent}>
        <a href={link} target="_blank">
          {content}
        </a>
      </div>
      <a href={link} target="_blank">
        <img src={downloadImage} alt="pdf icon" />
      </a>
    </div>
  )
}

const Documents = () => {
  return (
    <section className={documents}>
      <div className={`${description} small-12 medium-10 large-4 xlarge-6`}>
        <h2 className={heading}>Emso ESG Documents</h2>
      </div>
      <div className={`${documentList} small-12 large-8 xlarge-6 column`}>
        <div className={documentsContainer}>
          <div className={documentListHeading}>Useful Documents</div>
          <Document content="Emso ESG Policy" link={'/docs/EmsoESGPolicy.pdf'} />
          <hr />
          <Document content="Emso Diversity Disclosure – March 2021" link={'/docs/EmsoDiversityDocument.pdf'} />
          <hr />
          <Document content="Modern Slavery Policy 2023" link={'/docs/EmsoModernSlaveryAndHumanTraffickingStatement.pdf'} />
          <hr />
          <Document content="UK Stewardship Code" link={'/docs/UKStewardshipCode2019.pdf'} />
        </div>
      </div>
    </section>
  )
}

export default Documents
