import considerations from './images/esg/esg-considerations-icon.svg'
import privateCredit from './images/esg/private-credit-icon.svg'
import challenges from './images/esg/challenges-icon.svg'

export const heroData = {
  title: 'ESG',
  header: 'Emso has actively engaged with ESG since 2014, when it adopted its first formal ESG policy.',
  content: [
    'Emso is dedicated to conducting its business in accordance with optimal ethical and professional standards. As part of this aim, we recognize the importance of environment, social, and governance (“ESG”) issues in both our portfolios and our management company.',
  ],
}

export const boxes = [
  {
    image: considerations,
    title: 'ESG considerations',
    content:
      'The Emso research process includes an assessment of a wide range of factors, including a wide range of ESG considerations, that could influence an investment’s current or future value, risks, and impact on stakeholders associated with the investment. The specific ESG factors reviewed may vary based on the country, deal, industry, and investment structure under review.',
  },
  {
    image: privateCredit,
    title: 'Private Credit ESG',
    content:
      'The Emso Private Credit Team’s ESG investment process provides a framework for the identification and management of ESG risks and opportunities throughout the private credit investment cycle. The Team works with partner companies during each stage of the investment cycle to meet applicable ESG requirements, identify value add opportunities, and to monitor and manage ESG-related risks.',
  },
  {
    image: challenges,
    title: 'ESG challenges',
    content:
      'Emso believes that the “mainstreaming” of ESG is a critical development in the asset management industry, and supports industry-, investor- and regulator-lead initiatives. While the market contends with certain challenges, such as appropriate portfolio carbon footprinting methodologies, timeliness and accuracy of issuer disclosure, and the treatment of short-selling in an ESG context, Emso will continue to strive to be a thought leader amongst emerging market managers/investors.',
  },
]
