import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

const AnimateEntrance = ({ children, className, onEnter }) => {
  const [visible, setVisible] = React.useState(false)
  const handleEnter = React.useCallback(() => {
    setVisible(true)
    onEnter && onEnter(true)
  })
  return (
    <Waypoint onEnter={handleEnter}>
      <div data-hidden={!visible} className={className}>
        {children}
      </div>
    </Waypoint>
  )
}

AnimateEntrance.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onEnter: PropTypes.func,
}

AnimateEntrance.defaultProps = {
  className: '',
}

export default AnimateEntrance
