// extracted by mini-css-extract-plugin
export var content = "emso-documents-module__content";
export var description = "emso-documents-module__description emso-Layout-module__column";
export var documentContent = "emso-documents-module__documentContent";
export var documentList = "emso-documents-module__documentList";
export var documentListHeading = "emso-documents-module__documentListHeading";
export var documentWrapper = "emso-documents-module__documentWrapper";
export var documents = "emso-documents-module__documents emso-Layout-module__row";
export var documentsContainer = "emso-documents-module__documentsContainer";
export var heading = "emso-documents-module__heading";
export var lenseCount = "emso-documents-module__lenseCount";