import macroEconomicsAnalysisImage from './images/esg/esg-wind-turbines.jpg'
import focusCountriesIdentifiedImage from './images/esg/esg-diversity.jpg'
import opportunitiesIdentifiedImage from './images/esg/esg-engagement.jpg'

export default [
  {
    image: macroEconomicsAnalysisImage,
    type: 'A carbon neutral company',
    description:
      'Emso measured its global carbon footprint for 2019 and 2020 and offset 641 and 57 tonnes of carbon dioxide, respectively, through support for a solar cooker project in a rural area of Zhangye, Gansu province in northwestern China. As such, Emso received certification in 2019 and 2020 as Carbon Neutral Company under the Carbon Footprint Standard.',
  },
  {
    image: focusCountriesIdentifiedImage,
    type: 'Diversity and Inclusion',
    description:
      'Emso recognizes the importance of maintaining a diverse workforce in an inclusive working environment. Emso strives for fairness in hiring, actively seeks out diverse candidates, and regularly provides staff with training on anti-discrimination and anti-harassment.',
  },
  {
    image: opportunitiesIdentifiedImage,
    type: 'Engagement',
    description:
      'Emso actively participates on various industry ESG working groups, including the AIMA ESG working group. Emso has also historically spoken on industry panels which address topics related to the integration of ESG in portfolio management, ESG regulation, and/or management company ESG matters, like diversity and inclusion. Additionally, we strive to encourage as many industry stakeholders as possible, including our service providers and vendors, to adopt more sustainable business practices, and we have incorporated an ESG analysis as part of our standard due diligence process.',
  },
]
