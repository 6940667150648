import * as React from 'react'
import { Helmet } from 'react-helmet'
import Hero from '../components/Hero/Hero'
import Layout from '../layouts/Layout'
import { heroData, boxes } from '../data/esg'
import Heading from '../components/Heading/Heading'
import Boxes from '../components/Boxes/Boxes'
import firmData from '../data/esg-firm'
import NextSection from '../components/NextSection/NextSection'
import NumberedStack from '../components/NumberedStack/NumberedStack'
import Button from '../components/Button/Button'
import Documents from '../components/Esg/Documents'
import { ICON_DOWN_ARROW } from '../constants/constants'
import AnimateEntrance from '../components/Esg/AnimateEntrance'
import * as style from './esg.module.scss'
import heroImage from './images/esg-header-background.jpg'

const INVESTMENT_REF = 'INVESTMENT_ID'
const FIRM_REF = 'FIRM_REF'
// const GIVING_REF = 'GIVING_REF'

const EsgPage = () => {
  const investmentRef = React.createRef()
  const firmRef = React.createRef()
  const givingRef = React.createRef()

  const handleNavigate = e => {
    let ref
    switch (e.currentTarget.id) {
      case FIRM_REF:
        ref = firmRef
        break
      // case GIVING_REF:
      //   ref = givingRef
      //   break
      default:
        ref = investmentRef
    }
    window.scrollTo(0, ref.current.offsetTop - 100)
  }

  return (
    <Layout blueFooter={false} className={style.esgPage} hasDownArrow>
      <Helmet>
        <title>ESG - Emso</title>
        <meta property="og:title" content="ESG Emso" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://www.emso.com${heroImage}`} />
        <meta
          name="description"
          content="Emso's investment approach is sophisticated and granular. The team focuses on identifying idiosyncratic opportunities across emerging markets."
        />
      </Helmet>

      <Hero image={heroImage} {...heroData}>
        <AnimateEntrance>
          <div className={`${style.ctaWrapper} small-12 medium-10 large-7 xlarge-6`}>
            <div>
              <Button id={INVESTMENT_REF} onClick={handleNavigate} classNames={style.buttonPrimary} icon={ICON_DOWN_ARROW} rightIcon>
                Investment
              </Button>
              <Button id={FIRM_REF} onClick={handleNavigate} classNames={style.button}>
                {' '}
                Company{' '}
              </Button>
              {/*<Button id={GIVING_REF} onClick={handleNavigate} classNames={style.button}>*/}
              {/*  {' '}*/}
              {/*  Giving{' '}*/}
              {/*</Button>*/}
            </div>
          </div>
        </AnimateEntrance>
      </Hero>

      <NextSection />

      <div ref={investmentRef} className={style.investment}>
        <AnimateEntrance>
          <Heading
            title="Investment"
            content={[
              'A PRI signatory since 2015, Emso has developed methodologies for incorporating ESG into its research process',
            ]}
          />
        </AnimateEntrance>
        <AnimateEntrance>
          <Boxes content={boxes} />
        </AnimateEntrance>
      </div>

      <NextSection offset={-90} />

      <div ref={firmRef} className={style.company}>
        <Heading
          title="Company"
          content={['Emso is equally committed to the implementation of ESG principles at the management company level']}
        />
        <NumberedStack content={firmData} />
      </div>

      <NextSection />

      <AnimateEntrance>
        <Documents />
      </AnimateEntrance>

      <NextSection />
      {/*<div ref={givingRef} className={style.givingWrapper}>*/}
      {/*  <Giving />*/}
      {/*</div>*/}
    </Layout>
  )
}

export default EsgPage
